import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import { Seo } from "../../components/seo";
import axios from "axios";
import * as styles from "./nft.module.scss";
import Spinner from "../../components/spinner";

export const Head = () => <Seo title="Christmas creatures" />;

const Home = ({ data }) => {
  const [ files, setFiles ] = useState([]);
  useEffect(() => {
    (async () => {
      const files = await axios
        .get("https://brochain.blob.core.windows.net/christmas-creatures-showcase/files.json")
        .then(response => response.data);
      setFiles(files);
    })();
  }, []);

  return (
    <Layout>
      <h1>Christmas creatures</h1>
      <p>
        Christmas creatures is a collection inspired by the upcoming holiday season, slated to be released on{" "}
        <a href="https://www.stargaze.zone/">Stargaze</a>!
      </p>
      {files.length ? (
        <ul className={styles.frames}>
          {files.map(file => (
            <li className={styles.frame}>
              <img
                src={`https://brochain.blob.core.windows.net/christmas-creatures-showcase/${file.filename}`}
                alt={file.filename}
              />
            </li>
          ))}
        </ul>
      ) : (
        <React.Fragment>
          <Spinner /> Loading collection...
        </React.Fragment>
      )}
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query {
    allFile {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          publicURL
        }
      }
    }
  }
`;
